
@media (((min-width:600px) and (max-width: 768px))) {
    .my_property {
        display: flex !important; 
    }
    .options {
        display: none !important;
    }
    .view_property {
        font-size: 10px !important;
        padding: 0 0.25rem !important;
    }
}
th, td {
  white-space: nowrap !important;
}

th, td {
    @apply pl-4 px-3 md:px-7 text-sm text-left text-nowrap whitespace-nowrap
  }
  /* tbody {
    @apply bg-[#f1f1f1];
  } */
   tr {
    @apply pb-20 bg-[#f1f1f1];
   }
  td {
    @apply py-2 md:py-3 border-0;
  }
  th {
    @apply font-semibold py-4 md:py-5;
  }

  
@media ((min-width: 420px) and (max-width: 1009px)) {
  .my-table {
    width: 96vw;
  }
}